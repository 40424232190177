import React from "react"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ButtonPrimary } from "../components/utils/Buttons"
import { D2, D3, D4, H3, P } from "../components/utils/Typography"
import HeroImage from "../images/homeHero.jpg"

const Hero = styled.div`
  min-height: 70vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
`

const HeroCover = styled.div`
  background-color: #12141660;
  min-height: 70vh;
  padding: 0 80px;
  width: 100%;
  text-align: center;
  position: relative;
`

const Header = styled.div`
  margin-top: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
`

export default class Hire extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Post a Job" />
        <Hero style={{ backgroundImage: `url(${HeroImage})` }}>
          <HeroCover>
            <Container>
              <Header>
                <D2 style={{ color: "white" }} textAlign="center">
                  Reach Thousands Looking for their Next Home
                </D2>
                <D3
                  style={{
                    color: "white",
                    fontWeight: "800",
                  }}
                  textAlign="center"
                >
                  Post a job for{" "}
                  <span style={{ color: "#121416" }}>$10/day.</span>{" "}
                </D3>
              </Header>
            </Container>
          </HeroCover>
        </Hero>
        <Container style={{ minHeight: "40vh", padding: "20px 20px 40px" }}>
          <Row>
            <Col lg="6">
              <Row>
                <Col lg="12">
                  <H3 margin="60px 0 20px">Listing Perks:</H3>
                  <P>
                    <ul>
                      <li>Ad will be posted for 30 days.</li>
                      <li>4x social media post</li>
                      <li>Ad will be "Featured" for 10 days.</li>
                      <li>Sent out to our email subscribers 4x</li>
                    </ul>
                  </P>
                </Col>
              </Row>
            </Col>
            <Col lg="6">
              <Row>
                <Col lg="12">
                  <D4 margin="60px 0 20px" fontWeight="600">
                    <span style={{ color: "#0022e7", fontWeight: "700" }}>
                      Step 1.
                    </span>{" "}
                    Fill out Job Information
                  </D4>
                </Col>
                <Col lg="12">
                  <a
                    href="https://airtable.com/shrmAYW7OY0kHrmHo"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ButtonPrimary width="250px">Fill Out Form</ButtonPrimary>
                  </a>
                </Col>
                <Col lg="12">
                  <D4
                    margin="60px 0 20px"
                    fontWeight="600"
                    style={{ color: "#a1a1a1" }}
                  >
                    <span style={{ fontWeight: "700" }}>Step 2.</span> Submit
                    Payment ($300)
                  </D4>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}
